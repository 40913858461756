import React from 'react';
import { Flex } from 'workspace-core-ui';
import { GatsbyImage } from 'gatsby-plugin-image';

const sharedImageStyling = {
  isolation: 'isolate',
  position: 'fixed',
  width: 'clamp(100px, 100vh + 35rem, 1920px)',
  left: '50%',
  transform: 'translate(-50%, 0%)',
  bottom: 0,
  height: 'auto',
};

const BackgroundImage: React.FC = ({
  imageData,
  imageType,
  overrideImageStyling,
}: {
  imageType: 'gatsbyImage' | 'svg';
  imageData: object;
  // TODO: get right types for this
  overrideImageStyling?: object;
}) => (
  <div aria-hidden="true">
    {imageType === 'svg' && (
      <Flex
        dangerouslySetInnerHTML={{
          __html: imageData.data,
        }}
        sx={{
          svg: {
            ...sharedImageStyling,
            ...overrideImageStyling,
          },
        }}
      />
    )}
    {imageType === 'gatsbyImage' && (
      <Flex
        image={imageData}
        sx={{
          ...sharedImageStyling,
          ...overrideImageStyling,
        }}
        as={GatsbyImage}
      />
    )}
  </div>
);

export default BackgroundImage;
